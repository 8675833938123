import React from "react";
import ReactApexChart from "react-apexcharts";

interface PowerOutput {
  time: string;
  power_output: number;
  power_output_min: number;
  power_output_max: number;
}

interface PowerOutputChartProps {
  location?: string;
  startDate: string | null;
  endDate: string | null;
  powerOutputData: PowerOutput[];
  onError?: (message: string) => void;
}

const PowerOutputChart: React.FC<PowerOutputChartProps> = ({
  location,
  startDate,
  endDate,
  powerOutputData,
  onError,
}) => {
  const filteredData = powerOutputData.filter(({ time }) => {
    const dateTime = new Date(time).getTime();
    const start = startDate ? new Date(startDate).getTime() : null;
    const end = endDate ? new Date(endDate).getTime() : null;
    return (!start || dateTime >= start) && (!end || dateTime <= end);
  });

  // Prepare the series data
  const series = [
    {
      name: "Power Output (kW)",
      type: "line", // This is the line for power output
      data: filteredData.map(({ time, power_output }) => [
        new Date(time).getTime(),
        power_output,
      ]),
      zIndex: 10,
    },
    {
      name: "Minimum Power Output (kW)",
      type: "area",
      data: filteredData.map(({ time, power_output_min }) => [
        new Date(time).getTime(),
        power_output_min,
      ]),
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 0.2,
          gradientToColors: ["#28a745"],
          opacityFrom: 0.1,
          opacityTo: 0.1,
        },
      },
    },
    {
      name: "Maximum Power Output (kW)",
      type: "area",
      data: filteredData.map(({ time, power_output_max }) => [
        new Date(time).getTime(),
        power_output_max,
      ]),
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 0.2,
          gradientToColors: ["#28a745"],
          opacityFrom: 0.1,
          opacityTo: 0.1,
        },
      },
    },
  ];

  const ChartComponent = ReactApexChart;

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Power Output (kW)",
      },
      labels: {
        formatter: (value: number) => value.toFixed(2),
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    colors: ["#00A5E3", "#ff5745", "#28a745"],
    fill: {
      opacity: [1, 0.1, 0.1],
    },
    legend: {
      show: true,
    },
    title: {
      text: "Predicted Power Output",
      align: "left"
    }
  };

  return (
    <div className="power-output-chart-container">
      <ChartComponent options={options} series={series} height="350" />
    </div>
  );
};

export default PowerOutputChart;
