import NataliaKoziaraImage from "../../assets/images/natalia_koziara.png"
import JakubNowakImage from "../../assets/images/jakub_nowak.png"
import MateuszZbyslawImage from "../../assets/images/mateusz_zbyslaw.png"

export default function About() {
  return (
    <div className="text-justify w-full min-h-screen bg-gray-50 p-6">
      <div className="max-w-8xl mx-16 grid grid-cols-1 lg:grid-cols-2 gap-16">
        {/* Left Column */}
        <div className="space-y-6">
          {/* Abstract */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Abstract</h2>
            <p className="text-lg font-medium text-gray-700">
              The key objective of this project is to provide a reliable and cost-effective solution 
              for predicting long-term corrected wind data and estimating power generation at wind farm sites. 
              The system leverages machine learning models to offer accurate predictions of wind speed, direction, 
              and power output, enabling better planning and operational efficiency. The solution consists of two main components: 
              a React-based frontend user interface for data visualization and interaction, and a backend server 
              that processes data and serves predictions. The machine learning pipeline integrates various stages, 
              including data acquisition, exploration, preparation, and model training, with predictions generated 
              using linear regression and RNN models. Additionally, the system uses power curves abstracted from the 
              DTU10MW turbine model to estimate energy output. The outcome of this project is a system capable of 
              providing detailed wind and energy predictions. Users can interact with the system to analyze 
              environmental data, customize prediction parameters, and make informed decisions. The system's 
              functionality is validated through comprehensive performance evaluation and testing, ensuring the 
              reliability and accuracy of the predictions.
            </p>
          </div>

          {/* User Interaction */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">User Interaction</h2>
            <p className=" text-lg font-medium text-gray-700">
              The website is designed for ease of use, enabling users to:
            </p>
            <ul className=" list-disc list-inside text-lg font-medium text-gray-700 mt-2">
              <li>Choose the machine learning model (linear regression or RNN) and wind turbine type (8 MW or 14 MW) for analysis.</li>
              <li>Specify a date range to customize the displayed predictions.</li>
              <li>Access detailed plots for predicted wind speed, wind direction, power output, and power curves.</li>
            </ul>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          {/* Purpose and Content Description */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Purpose and Content Description</h2>
            <p className=" text-lg font-medium text-gray-700">
              The primary goal of this project is to develop an interactive system that utilizes machine learning models to predict long-term corrected wind data, offering a cost-effective alternative to existing neural-network-based methods.
            </p>
            <p className=" text-lg font-medium text-gray-700 mt-4">
              The project consists of two main components:
            </p>
            <ol className=" list-decimal list-inside text-lg font-medium text-gray-700 mt-2">
              <li>
                <strong>Machine Learning Pipeline</strong>: Data acquisition, exploration, preparation, model development, and wind data prediction.
              </li>
              <li>
                <strong>Software Development</strong>: Creation of a web server and frontend interface.
              </li>
            </ol>
            <p className=" text-lg font-medium text-gray-700 mt-4">
              This website is the frontend component of the project. Its purpose is to visualize the outputs of the machine learning models developed during the project—specifically predictions of wind speed and power generation.
            </p>
            <p className=" text-lg font-medium text-gray-700 mt-4">
              Users can interact with the website to explore the predictions and insights generated from the models.
            </p>
          </div>

          {/* Features of the Website */}
          <div className=" bg-white shadow-md rounded-lg p-6">
            <h2 className=" text-2xl font-bold text-gray-800 mb-4">Features of the Website</h2>
            <p className="text-lg font-medium text-gray-700">
              This website provides the following features:
            </p>
            <ul className="list-disc list-inside text-lg font-medium text-gray-700 mt-2">
              <li>View location characteristics and images of the MMIJ research platform.</li>
              <li>Explore wind turbine characteristics, including power curves for 8 MW and 14 MW turbines <br></br>abstracted from the DTU10MW power curve.</li>
              <li>Examine model performance metrics, hyperparameters, and details about the linear regression and RNN models.</li>
              <li>Visualize predictions for wind speed, wind direction, power output, and power curves.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Supervision and Cooperation Section */}
      <div className="bg-white shadow-md mt-10 p-20 rounded-lg max-w-fit mx-auto">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Supervision and Cooperation</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="text-justify">
            <p className="text-lg font-medium text-gray-700">
              The project was supervised by <strong>Frederik Thorning Bjørn</strong>.
            </p>
            <p className="text-lg font-medium text-gray-800">
              VIA University College - campus Horsens, Denmark
            </p>
            <p className="text-lg font-medium text-gray-700">
              Email: <a href="mailto:frbj@viauc.dk" className="text-[#0D9CA5]">frbj@viauc.dk</a>
            </p>
          </div>
          <div className="text-justify">
            <p className="text-lg font-medium text-gray-700">
              The project was executed in cooperation with <strong>Siemens Gamesa</strong>.
            </p>
            <p className="text-lg font-medium text-gray-700">
            Siemens Gamesa Renewable Energy - Vejle, Denmark
            </p>
            <p className="text-lg font-medium text-gray-700">
              Website: <a href="https://www.siemensgamesa.com/global/en/home.html" className="text-[#0D9CA5]">siemensgamesa.com</a>
            </p>
          </div>
        </div>
      </div>


      {/* Authors Section */}
      <div className="bg-white shadow-md mt-10 p-6 rounded-lg max-w-fit mx-auto">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Authors</h2>
        <div className="flex flex-col md:flex-row justify-evenly items-center space-y-6 md:space-y-0 md:space-x-6">
          {/* Natalia Koziara */}
          <div className="flex items-center  rounded-lg p-6 w-full md:w-auto">
            <img
              src={NataliaKoziaraImage}
              alt="Natalia Koziara"
              className="rounded-full w-32 h-32 mr-6"
            />
            <div>
              <p className="text-lg font-medium text-gray-800">Natalia Koziara</p>
              <p className="text-lg font-medium text-gray-700">
                Email: <a href="mailto:315275@via.dk" className="text-[#0D9CA5]">315275@via.dk</a>
              </p>
              <p className="text-lg font-medium text-gray-700">
                <a href="https://github.com/koziara" className="text-[#0D9CA5]">github.com/koziara</a>
              </p>
            </div>
          </div>

          {/* Jakub Nowak */}
          <div className="flex items-center rounded-lg p-6 w-full md:w-auto">
            <img
              src={JakubNowakImage}
              alt="Jakub Nowak"
              className="rounded-full w-32 h-32 mr-6"
            />
            <div>
              <p className="text-lg font-medium text-gray-800">Jakub Nowak</p>
              <p className="text-lg font-medium text-gray-700">
                Email: <a href="mailto:315252@via.dk" className="text-[#0D9CA5]">315252@via.dk</a>
              </p>
              <p className="text-lg font-medium text-gray-700">
                <a href="https://github.com/ham222" className="text-[#0D9CA5]">github.com/ham222</a>
              </p>
            </div>
          </div>

          {/* Mateusz Zbysław */}
          <div className="flex items-center  rounded-lg p-6 w-full md:w-auto">
            <img
              src={MateuszZbyslawImage}
              alt="Mateusz Damian Zbysław"
              className="rounded-full w-32 h-32 mr-6"
            />
            <div>
              <p className="text-lg font-medium text-gray-800">Mateusz Damian Zbysław</p>
              <p className="text-lg font-medium text-gray-700">
                Email: <a href="mailto:315192@via.dk" className="text-[#0D9CA5]">315192@via.dk</a>
              </p>
              <p className="text-lg font-medium text-gray-700">
                <a href="https://github.com/ZbyslawMateo" className="text-[#0D9CA5]">github.com/ZbyslawMateo</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Acknowledgments Section */}
      <div className="bg-white shadow-md mt-10 p-6 rounded-lg max-w-fit mx-auto">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Acknowledgments</h2>
        <p className="text-lg font-medium text-gray-700">
          We would like to acknowledge the sources of the data and resources used in this project:
        </p>
        <ul className="list-disc list-inside text-lg font-medium text-gray-700 mt-2 space-y-2">
          <li>
            <strong>MAST Data:</strong> Provided by <a href="https://offshorewind-measurements.tno.nl/en/measurement-locations/meteomast-ijmuiden-mmij/" target="_blank" rel="noopener noreferrer" className="text-[#0D9CA5] hover:text-cyan-300">TNO</a>, collected from the Meteomast IJmuiden (MMIJ) measurement platform.
          </li>
          <li>
            <strong>MESO Data:</strong> Received from <a href="https://www.siemensgamesa.com/" target="_blank" rel="noopener noreferrer" className="text-[#0D9CA5] hover:text-cyan-300">Siemens Gamesa</a>, originally derived from the <a href="https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-single-levels" target="_blank" rel="noopener noreferrer" className="text-[#0D9CA5] hover:text-cyan-300">ERA5 model</a>.
          </li>
          <li>
            <strong>Power Curves:</strong> Obtained using the <a href="https://topfarm.pages.windenergy.dtu.dk/PyWake/index.html" target="_blank" rel="noopener noreferrer" className="text-[#0D9CA5] hover:text-cyan-300">PyWake module</a>, including curves abstracted from the DTU10MW turbine model.
          </li>
          <li>
            <strong>Main Page Photo:</strong> Sourced from <a href="https://www.pexels.com/photo/black-and-white-ship-on-sea-3808621/" target="_blank" rel="noopener noreferrer" className="text-[#0D9CA5] hover:text-cyan-300">Pexels</a>, a platform for free stock photos. Author: Jan-Rune Smenes Reite.
          </li>
        </ul>
</div>

    </div>
  );
}