import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

interface CustomDatePickerProps {
  label: string;
  value: string | null;
  onChange: (date: string) => void;
  defaultDate?: Moment;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  value,
  onChange,
  defaultDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);

  // Hardcoded bounding values
  const minDate = moment('2003-01-01', 'YYYY-MM-DD');
  const maxDate = moment('2023-12-31', 'YYYY-MM-DD');

  useEffect(() => {
    if (defaultDate) {
      const boundedDate = moment.max(moment.min(defaultDate, maxDate), minDate);
      setSelectedDate(boundedDate);
      onChange(boundedDate.format('DD/MM/YYYY'));
    } else if (value) {
      const initialDate = moment(value, 'DD/MM/YYYY');
      const boundedDate = moment.max(moment.min(initialDate, maxDate), minDate);
      setSelectedDate(boundedDate);
    }
  }, [defaultDate, value, onChange, minDate, maxDate]);

  const handleDateChange = (date: Moment | null) => {
    if (date) {
      const boundedDate = moment.max(moment.min(date, maxDate), minDate);
      setSelectedDate(boundedDate);
      onChange(boundedDate.format('DD/MM/YYYY'));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        format="DD/MM/YYYY"
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
