import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModelDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  modelDetails: {
    name: string;
    evaluation_metric: string;
    evaluation_metric_value: Record<string, number>;
    loss_metric: string;
    loss_metric_value: Record<string, number>;
    hyperparameters?: Record<string, string | number | null>;
  } | null;
  modelNameMapping?: {
    [key: string]: string;
  };
}

const ModelDetailsModal: React.FC<ModelDetailsModalProps> = ({
  isOpen,
  onClose,
  modelDetails,
  modelNameMapping = {}
}) => {
  if (!modelDetails) {
    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>Model Details</Grid>
            <Grid item>
              <IconButton onClick={onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">No model details available</Typography>
        </DialogContent>
        <DialogActions>
          <button
            onClick={onClose}
            className="text-gray-800 border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 py-2 px-4 rounded-md"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    );
  }

  const humanReadableModelName = Object.keys(modelNameMapping).find(
    key => modelNameMapping[key] === modelDetails.name
  ) || modelDetails.name;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>Model Details: {humanReadableModelName}</Grid>
          <Grid item>
            <IconButton onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
     
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              Model Performance
            </Typography>
          </Grid>

          {/* Evaluation Metric */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" fontWeight="bold">
              Evaluation Metric: {modelDetails.evaluation_metric.toUpperCase()}
            </Typography>
            {Object.entries(modelDetails.evaluation_metric_value).map(([key, value]) => (
              <Grid container key={key}>
                <Grid item xs={6}>
                  <Typography variant="body2">{key}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{value.toFixed(3)}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
           
          {/* Loss Metric */}
          <Grid item xs={12} mt={2}>
            <Typography variant="subtitle2" fontWeight="bold">
              Loss Metric: {modelDetails.loss_metric.toUpperCase()}
            </Typography>
            {Object.entries(modelDetails.loss_metric_value).map(([key, value]) => (
              <Grid container key={key}>
                <Grid item xs={6}>
                  <Typography variant="body2">{key}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">{value.toFixed(3)}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          {/* Hyperparameters (only if not empty) */}
          {modelDetails.hyperparameters && Object.keys(modelDetails.hyperparameters).length > 0 && (
            <>
              <Grid item xs={12} mt={2}>
                <Typography variant="subtitle2" fontWeight="bold">
                  Hyperparameters
                </Typography>
              </Grid>

              {Object.entries(modelDetails.hyperparameters).map(([key, value]) => (
                <Grid container key={key} pl={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2">{key}:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">{value !== null ? String(value) : 'N/A'}</Typography>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </DialogContent>
     
      <DialogActions>
        <button
          onClick={onClose}
          className="text-gray-800 border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 py-2 px-4 rounded-md"
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ModelDetailsModal;