import React from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence, motion } from 'framer-motion';
import Front from "./pages/Front/Front";
import Locations from "./pages/Locations/Locations";
import About from "./pages/About/About";
import Navbar from "./components/Navbar";
import "./App.css";

function App() {
  return (
    <div className=" h-screen text-slate-300 text-lg font-semibold overflow-y-hidden">
      <div className="App h-full overflow-scroll">
        <Navbar />
        <div className="h-full pt-12">
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={
              <motion.div
              initial={{ y: "-3vw", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "100vw", opacity: 0 }}
              transition={{ duration: 1.5 }}
            >
            <Front />
            </motion.div>
            } >
            </Route>
            <Route path="/about" element={
              <motion.div
              initial={{ y: "-3vw", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "100vw", opacity: 0 }}
              transition={{ duration: 1.5 }}
            >
            <About />
            </motion.div>
            } >
            </Route>            
            <Route path="/location/:location" element={<motion.div
              initial={{ y: "-3vw", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "100vw", opacity: 0 }}
              transition={{ duration: 1.5 }}
            >
            <Locations />
            </motion.div>
            } >
            </Route>
          </Routes>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default App;
