import React from "react";

interface ErrorPopupProps {
  message: string | null;
  onClose: () => void;
  isVisible: boolean;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({
  message,
  onClose,
  isVisible,
}) => {
  if (!isVisible || !message) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "20%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        border: "2px solid #FF4560",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        zIndex: 1000,
        textAlign: "center",
      }}
    >
      <h3 style={{ margin: "0 0 10px 0", fontSize: "18px", color: "#FF4560" }}>
        Error
      </h3>
      <p style={{ margin: "0 0 20px 0", fontSize: "14px" }}>{message}</p>
      <button
        onClick={onClose}
        style={{
          padding: "10px 20px",
          backgroundColor: "#FF4560",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Close
      </button>
    </div>
  );
};

export default ErrorPopup;
