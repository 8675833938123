import { NavLink } from "react-router-dom";
import LocationButton from "../../components/LocationButton";
import MMIJLocationImage from "../../assets/images/MMIJ_location.png";
import WindmillsImage from "../../assets/images/power_curves.png";

export default function Front() {
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="w-full">
      {/* First Full-Screen Section */}
      <div className="relative min-h-screen bg-cover bg-center bg-bc-front-1 flex flex-col justify-center items-center pt-10">
        {/* Text moved to the top-left corner */}
        <div className="absolute top-10 left-10 text-left text-white">
        <div
            className="mt-4 text-2xl font-light text-white leading-relaxed drop-shadow-lg"
            style={{ textShadow: "3px 3px 8px rgba(0, 0, 0, 0.3)" }}
          >
            <p>
              To see LTC wind data prediction, choose the available
              <span
                onClick={() => scrollToSection("location-section")}
                className="text-[#0D9CA5] cursor-pointer hover:text-cyan-300"
              >
                {" "}
                location
              </span>{" "}
              below - MMIJ.
              <br />
              For more details about the location and wind mills, scroll to the
              <span
                onClick={() => scrollToSection("bottom-section")}
                className="text-[#0D9CA5] cursor-pointer hover:text-cyan-300"
              >
                {" "}
                bottom
              </span>{" "}
              of the page.
              <br />
              For more information about the project, please go to the
              <NavLink
                to="/about"
                className="text-[#0D9CA5] hover:text-cyan-300"
              >
                {" "}
                about page
              </NavLink>
              .
            </p>
          </div>
        </div>

        {/* Buttons centered */}
        <div className="mt-20 flex justify-center space-x-20">
          <LocationButton
            title="MMIJ"
            coordinates="N52°50.89' E3°26.14'"
            link="/location/mmij"
          />
        </div>
      </div>

      {/* Section with Research Site */}
      <div 
      id="location-section"
      className="min-h-screen bg-cover bg-center bg-bc-front-2 flex flex-col justify-center items-center py-8 px-6">
        {/* Research Site Card */}
        <div className="flex flex-col md:flex-row items-center bg-white shadow-2xl rounded-lg overflow-hidden max-w-7xl mx-auto my-6">
          {/* Text Section */}
          <div className="p-6 md:w-1/2">
            <h3 className="text-3xl font-bold text-gray-800 mb-4">
            MMIJ Research Platform
            </h3>
            <p className="text-lg font-medium text-gray-700">
              The MMIJ platform, located in the North Sea, serves as a vital
              data source for training machine learning models. This data has
              been instrumental in predicting wind conditions and estimating
              energy generation at the site.
            </p>
          </div>
          {/* Image Section */}
          <div className="md:w-1/2">
            <img
              src={MMIJLocationImage}
              alt="MMIJ Research Platform"
              className="object-cover w-full h-full"
            />
          </div>
        </div>

        {/* Turbines Card */}
        <div 
        id="bottom-section"
        className="flex flex-col md:flex-row items-center bg-white shadow-2xl rounded-lg overflow-hidden max-w-7xl mx-auto my-6">
          {/* Image Section */}
          <div className="md:w-1/2">
            <img
              src={WindmillsImage}
              alt="Windmills"
              className="object-cover w-full h-full"
            />
          </div>
          {/* Text Section */}
          <div className="p-12 md:w-1/2">
            <h3 className="text-4xl font-bold text-gray-800 mb-4">
              DTU 8 MW & 14 MW Turbines
            </h3>
            <ul className="list-disc list-inside text-lg font-medium text-gray-700 space-y-4">
              <li>
                <strong>8 MW Turbine: </strong> 
                 Derived from the DTU10MW wind turbine power curve, with nominal power scaled to a maximum of 8.0 MW.
              </li>
              <li>
                <strong>14 MW Turbine: </strong>
                 Derived from the DTU10MW wind turbine power curve, with nominal power scaled to a maximum of 14.0 MW.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
