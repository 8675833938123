import React from "react";
import ReactApexChart from "react-apexcharts";

interface Prediction {
  time: string;
  wind_direction: number;
}

interface WindDirectionChartProps {
  location?: string;
  startDate: string | null;
  endDate: string | null;
  predictions: Prediction[];
  onError?: (message: string) => void;
}

const WindDirectionChart: React.FC<WindDirectionChartProps> = ({
  location,
  startDate,
  endDate,
  predictions,
  onError,
}) => {
  const filteredPredictions = predictions.filter(({ time }) => {
    const dateTime = new Date(time).getTime();
    const start = startDate ? new Date(startDate).getTime() : null;
    const end = endDate ? new Date(endDate).getTime() : null;
    return (!start || dateTime >= start) && (!end || dateTime <= end);
  });

  const series = [
    {
      name: "Wind Direction (degrees)",
      type: "line",
      data: filteredPredictions.map(({ time, wind_direction }) => [
        new Date(time).getTime(),
        wind_direction,
      ]),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Wind Direction (degrees)",
      },
      min: 0,
      max: 360,
      labels: {
        formatter: (value: number) => {
          return value.toFixed(0);
        },
      },
    },
    tooltip: {
      shared: false,
      intersect: false,
    },
    colors: ["#FF6384"],
    legend: {
      show: true,
    },
    title: {
      text: 'Predicted Wind Direction',
      align: 'left'
    }
  };

  return (
    <div className="wind-direction-chart-container">
      <ReactApexChart options={options} series={series} height="300" />
    </div>
  );
};

export default WindDirectionChart;