import React from "react";
import ReactApexChart from "react-apexcharts";

interface Prediction {
  time: string;
  wind_speed: number;
  wind_direction: number;
  wind_speed_min: number;
  wind_speed_max: number;
}

interface WindChartProps {
  location?: string;
  startDate: string | null;
  endDate: string | null;
  predictions: Prediction[];
  onError?: (message: string) => void;
}

const WindChart: React.FC<WindChartProps> = ({
  location,
  startDate,
  endDate,
  predictions,
  onError,
}) => {
  const filteredPredictions = predictions.filter(({ time }) => {
    const dateTime = new Date(time).getTime();
    const start = startDate ? new Date(startDate).getTime() : null;
    const end = endDate ? new Date(endDate).getTime() : null;
    return (!start || dateTime >= start) && (!end || dateTime <= end);
  });

  const series = [
    {
      name: "Wind Speed (m/s)",
      type: "line",
      data: filteredPredictions.map(({ time, wind_speed }) => [
        new Date(time).getTime(),
        wind_speed,
      ]),
      zIndex: 10,
    },
    {
      name: "Minimum Wind Speed (m/s)",
      type: "area",
      data: filteredPredictions.map(({ time, wind_speed_min }) => [
        new Date(time).getTime(),
        wind_speed_min,
      ]),
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#28a745"],
          opacityFrom: 0.1,
          opacityTo: 0.1,
        },
      },
    },
    {
      name: "Maximum Wind Speed (m/s)",
      type: "area",
      data: filteredPredictions.map(({ time, wind_speed_max }) => [
        new Date(time).getTime(),
        wind_speed_max,
      ]),
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#28a745"],
          opacityFrom: 0.1,
          opacityTo: 0.1,
        },
      },
    },
  ];

  const ChartComponent = ReactApexChart;

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: [
      {
        title: {
          text: "Wind Speed (m/s)",
        },
        labels: {
          formatter: (value: number) => {
            return value.toFixed(2);
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
    },
    colors: ["#00A5E3", "#ff5745", "#28a745"],
    fill: {
      opacity: [1, 0.1, 0.1],
    },
    legend: {
      show: true,
    },
    title: {
      text: 'Predicted Wind Speed',
      align: 'left'
    }
  };

  return (
    <div className="wind-chart-container">
      <ChartComponent options={options} series={series} height="300" />
    </div>
  );
};

export default WindChart;
