import { NavLink } from "react-router-dom";
import { Button } from "@headlessui/react";

interface LocationButtonProps {
  title: string;
  coordinates: string;
  link: string;
}

export default function LocationButton({
  title,
  coordinates,
  link,
}: LocationButtonProps) {
  return (
    <NavLink to={link}>
      <Button className="relative rounded-3xl border-2 border-[#0D9CA5] border-opacity-70 py-4 px-8 text-[#0D9CA5] bg-white bg-opacity-80 hover:bg-opacity-90 transition duration-300">
        <span className="block text-3xl font-bold tracking-[0.4em]">
          {title}
        </span>
        <span className="block text-m tracking-normal mt-1">{coordinates}</span>
      </Button>
    </NavLink>
  );
}
