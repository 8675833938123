import { NavLink } from "react-router-dom";
import { FaHome, FaInfoCircle } from "react-icons/fa";

export default function Navbar() {
  return (
    <>
      <div className="w-full h-12 bg-white bg-opacity-50 flex justify-end items-stretch border-b-2 border-[#0D9CA5] fixed top-0 z-50 shadow-md backdrop-blur-lg">
        <nav className="flex">
          <NavLink to="/">
            <div className="h-full px-6 flex items-center text-[#0D9CA5] hover:text-slate-500 transition duration-300">
              <FaHome size={24} />
            </div>
          </NavLink>
          <NavLink to="/about">
            <div className="h-full px-6 flex items-center text-[#0D9CA5] hover:text-slate-500 transition duration-300">
              <FaInfoCircle size={24} />
            </div>
          </NavLink>
        </nav>
      </div>
    </>
  );
}
