/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react';

interface UseFetchModelsParams {
  location: string;
  API_URL: string;
  setModels: (predictions: any) => void;
  setErrorMessage: (message: string) => void;
  setShowErrorPopup: (show: boolean) => void;
}

const useFetchModels = ({
  location,
  API_URL,
  setModels,
  setErrorMessage,
  setShowErrorPopup,
}: UseFetchModelsParams) => {
  useEffect(() => {
    if (location) {
  
  
      fetch(`${API_URL}/api/models?location=${location}`)
        .then((response) => response.json())
        .then((data) => {
          setModels(data);
        })
        .catch((error) => {
          console.error("Error fetching models:", error);
          setErrorMessage("Failed to fetch models.");
          setShowErrorPopup(true);
        });
    }
  }, [location, API_URL, setModels, setErrorMessage, setShowErrorPopup]);
};

export default useFetchModels;
