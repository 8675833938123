/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import moment from "moment";

interface UseFetchPowerOutputParams {
  location: string;
  model: string;
  turbine: string;
  startDate: string | null;
  endDate: string | null;
  API_URL: string;
  setPowerOutputData: (data: any) => void;
  setErrorMessage: (message: string) => void;
  setShowErrorPopup: (show: boolean) => void;
}

const useFetchPowerOutput = ({
  location,
  model,
  turbine,
  startDate,
  endDate,
  API_URL,
  setPowerOutputData,
  setErrorMessage,
  setShowErrorPopup,
}: UseFetchPowerOutputParams) => {
  useEffect(() => {
    if (location && model && turbine) {
      const formattedStartDate = startDate ? moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
      const formattedEndDate = endDate ? moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null;

      const queryParams = new URLSearchParams();
      if (formattedStartDate) queryParams.append("start", formattedStartDate);
      if (formattedEndDate) queryParams.append("end", formattedEndDate);
      queryParams.append("model", model);
      queryParams.append("turbine", turbine);

      fetch(`${API_URL}/api/poweroutput?location=${location}&${queryParams.toString()}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch power output data: ${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => {
          setPowerOutputData(data);
        })
        .catch((error) => {
          console.error("Error fetching power output:", error);
          setErrorMessage("Failed to fetch power output data.");
          setShowErrorPopup(true);
        });
    }
  }, [location, model, turbine, startDate, endDate, API_URL, setPowerOutputData, setErrorMessage, setShowErrorPopup]);
};

export default useFetchPowerOutput;
