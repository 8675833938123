/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react';
import moment from 'moment'

interface UseFetchPredictionsParams {
  location: string;
  model: string;
  startDate: string | null;
  endDate: string | null;
  API_URL: string;
  setPredictions: (predictions: any) => void;
  setErrorMessage: (message: string) => void;
  setShowErrorPopup: (show: boolean) => void;
}

const useFetchPredictions = ({
  location,
  model,
  startDate,
  endDate,
  API_URL,
  setPredictions,
  setErrorMessage,
  setShowErrorPopup,
}: UseFetchPredictionsParams) => {
  useEffect(() => {
    if (location && model) {
      const formattedStartDate = startDate ? moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
      const formattedEndDate = endDate ? moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD") : null;
  
      const queryParams = new URLSearchParams();
      if (formattedStartDate) queryParams.append("start", formattedStartDate);
      if (formattedEndDate) queryParams.append("end", formattedEndDate);
      queryParams.append("model", model);
  
      fetch(`${API_URL}/api/predictions?location=${location}&${queryParams.toString()}`)
        .then((response) => response.json())
        .then((data) => {
          setPredictions(data);
        })
        .catch((error) => {
          console.error("Error fetching predictions:", error);
          setErrorMessage("Failed to fetch wind predictions.");
          setShowErrorPopup(true);
        });
    }
  }, [location, model, startDate, endDate, API_URL, setPredictions, setErrorMessage, setShowErrorPopup]);
};

export default useFetchPredictions;
