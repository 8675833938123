/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import WindChart from "./WindChart";
import WindDirectionChart from "./WindDirectionChart";
import ErrorPopup from "../../components/ErrorPopup";
import CustomDatePicker from "../../components/CustomDatePicker";
import PowerCurveChart from "./PowerCurveChart";
import PowerOutputChart from "./PowerOutputChart";
import Dropdown from "../../components/Dropdown";
import ModelDetailsModal from "../../components/ModelDetailsModal";
import useFetchPredictions from "../../hooks/useFetchPredictions";
import useFetchModels from "../../hooks/useFetchModels";
import useFetchPowerOutput from "../../hooks/useFetchPowerOutput";

const modelNameMapping = {
  "Simple RNN": "simplernn",
  "Linear with season": "linearbaselineseason",
} as const;

const turbineNameMapping = {
  "DTU 8 MW": "dtu8mw",
  "DTU 14 MW": "dtu14mw",
} as const;

export default function Locations() {
  const [selectedModel, setSelectedModel] = useState<string>(
    Object.values(modelNameMapping)[0]
  );
  
  const [models, setModels] = useState<any[]>([]);
  
  const [isModelDetailsOpen, setIsModelDetailsOpen] = useState(false);
  const [selectedModelDetails, setSelectedModelDetails] = useState<any>(null);
  const [powerOutputData, setPowerOutputData] = useState<any[]>([]);
  const [selectedTurbine, setSelectedTurbine] = useState<string>("dtu14mw");

  const { location } = useParams<{ location: string }>();

  const [startDate, setStartDate] = useState<string | null>(
    moment("01-01-2003", "DD-MM-YYYY").format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState<string | null>(
    moment("08-01-2003", "DD-MM-YYYY").format("DD/MM/YYYY")
  );
  
  const [predictions, setPredictions] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);

  const selectedLocation = location ?? "mmij";
  const API_URL = process.env.REACT_APP_API_BASE_URL ?? '';

  useFetchModels({
    location: selectedLocation,
    API_URL,
    setModels,
    setErrorMessage,
    setShowErrorPopup
  });

  useFetchPredictions({
    location: selectedLocation,
    model: selectedModel,
    startDate,
    endDate,
    API_URL,
    setPredictions,
    setErrorMessage,
    setShowErrorPopup,
  });

  useFetchPowerOutput({
    location: selectedLocation,
    model: selectedModel,
    turbine: selectedTurbine,
    startDate,
    endDate,
    API_URL,
    setPowerOutputData,
    setErrorMessage,
    setShowErrorPopup,
  });

  const turbineDropdownItems = Object.keys(turbineNameMapping).map((label) => ({
    label,
    onClick: () => setSelectedTurbine(turbineNameMapping[label as keyof typeof turbineNameMapping]),
  }));  
  
  const unavailableStart = moment("31/11/2011", "DD/MM/YYYY");
  const unavailableEnd = moment("11/03/2016", "DD/MM/YYYY");
  
  const [isUnavailable, setIsUnavailable] = useState<boolean>(false);
  
  const updateUnavailableStatus = (start: moment.Moment, end: moment.Moment) => {
    setIsUnavailable(
      start.isBetween(unavailableStart, unavailableEnd, undefined, "[)") ||
      end.isBetween(unavailableStart, unavailableEnd, undefined, "[)") ||
      (start.isSameOrBefore(unavailableStart) && end.isSameOrAfter(unavailableEnd))
    );
  };
  
  
  const handleStartDateChange = (value: string) => {
    const start = moment(value, "DD/MM/YYYY");
    const end = moment(endDate, "DD/MM/YYYY");
  
    setStartDate(value);
  
    if (endDate && start.isAfter(end)) {
      setErrorMessage("Start date cannot be after end date.");
      setShowErrorPopup(true);
    } else {
      setErrorMessage(null);
      setShowErrorPopup(false);
    }
  
    updateUnavailableStatus(start, end);
  };
  
  const handleEndDateChange = (value: string) => {
    const start = moment(startDate, "DD/MM/YYYY");
    const end = moment(value, "DD/MM/YYYY");
  
    setEndDate(value);
  
    if (startDate && end.isBefore(start)) {
      setErrorMessage("End date cannot be before start date.");
      setShowErrorPopup(true);
    } else {
      setErrorMessage(null);
      setShowErrorPopup(false);
    }
  
    updateUnavailableStatus(start, end);
  };

  const modelDropdownItems = Object.keys(modelNameMapping).map((label) => ({
    label,
    onClick: () => handleModelSelect(modelNameMapping[label as keyof typeof modelNameMapping]),
  }));

  const handleModelSelect = (modelName: string) => {
    setSelectedModel(modelName);
  };

  const handleCheckModelDetails = () => {
    const modelDetails = models.find((model) => model.name === selectedModel);

    setSelectedModelDetails(
      modelDetails
        ? {
            name: modelDetails.name,
            evaluation_metric: modelDetails.evaluation_metric || 'N/A',
            evaluation_metric_value: modelDetails.evaluation_metric_value || 'N/A',
            loss_metric: modelDetails.loss_metric || 'N/A',
            loss_metric_value: modelDetails.loss_metric_value || 'N/A',
            hyperparameters: modelDetails.hyperparameters || {}
          }
        : null
    );
  
    setIsModelDetailsOpen(true);
  };

  const turbineDropdownButtonText = 
    Object.keys(turbineNameMapping).find(
      (key) => turbineNameMapping[key as keyof typeof turbineNameMapping] === selectedTurbine
    ) || "Select Turbine"
  
  const modelDropdownButtonText = 
    Object.keys(modelNameMapping).find(
      (key) => modelNameMapping[key as keyof typeof modelNameMapping] === selectedModel
    ) || "Select Model";

  return (
    <>
      <div className="location-page text-slate-600">
        <h2 className="text-xl mt-6">
          This is the locations page. Below is the wind data chart for {selectedLocation.toUpperCase()}:
        </h2>
        <div className="date-picker-container flex justify-around mt-6 items-center">
          <CustomDatePicker
            label="Start Date:"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <CustomDatePicker
            label="End Date:"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <Dropdown
            buttonText={turbineDropdownButtonText} 
            items={turbineDropdownItems}
          />
          <Dropdown
            buttonText={modelDropdownButtonText}
            items={modelDropdownItems}  
          />
          <button
            onClick={handleCheckModelDetails}
            className="text-gray-800 border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 py-2 px-4 rounded-md text-sm mt-2"
            disabled={!selectedModel}
          >
            Check Model Details
          </button>
        </div>
        <ErrorPopup
          message={errorMessage}
          onClose={() => setShowErrorPopup(false)}
          isVisible={showErrorPopup}
        />
        <div className="">{isUnavailable && (
          <p className="text-red-500 mt-4">
            The prediction data is not available for the range: 02/11/2011 to 11/03/2016, because the measurements
            are available for this time period.
          </p>
        )}</div>
        <WindChart
          location={selectedLocation}
          startDate={startDate}
          endDate={endDate}
          predictions={predictions}
        />
        <WindDirectionChart
          location={selectedLocation}
          startDate={startDate}
          endDate={endDate}
          predictions={predictions}
        />
        <PowerOutputChart
          location={selectedLocation}
          startDate={startDate}
          endDate={endDate}
          powerOutputData={powerOutputData}
        />
        <PowerCurveChart turbine={selectedTurbine} API_URL={API_URL} />
          
        <ModelDetailsModal
          isOpen={isModelDetailsOpen}
          onClose={() => setIsModelDetailsOpen(false)}
          modelDetails={selectedModelDetails}
          modelNameMapping={modelNameMapping}
        />
      </div>
    </>
  );
}
