import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface PowerCurveData {
  wind_speed: number;
  power_output: number;
}

interface PowerCurveChartProps {
  turbine: string;
  API_URL: string;
}

const PowerCurveChart: React.FC<PowerCurveChartProps> = ({ turbine, API_URL }) => {
  const [powerCurveData, setPowerCurveData] = useState<PowerCurveData[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!turbine) return;

    fetch(`${API_URL}/api/powercurves?turbine=${turbine}`)
      .then((response) => response.json())
      .then((data) => {
        setPowerCurveData(data);
      })
      .catch((error) => {
        console.error('Error fetching power curve data:', error);
        setErrorMessage('Failed to fetch power curve data.');
      });
  }, [turbine, API_URL]);

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  if (!powerCurveData) {
    return <div>Loading power curve data...</div>;
  }

  const xValues = powerCurveData.map((item) => item.wind_speed);
  const yValues = powerCurveData.map((item) => item.power_output);

  const series = [
    {
      name: 'Power Output',
      data: xValues.map((x, i) => [x, yValues[i]]),
    },
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: xValues,
      title: {
        text: 'Wind Speed (m/s)',
      },
      tickAmount: 5,
      labels: {
        formatter: (value: string) => parseFloat(value) % 1 === 0 ? value : '',
      },
    },
    yaxis: {
      title: {
        text: 'Power Output (kW)',
      },
      labels: {
        formatter: (value: number) => value.toFixed(2),
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    colors: ['#FF4560'],
    title: {
      text: "Power curve",
      align: "left"
    }
  };

  return (
    <div className="power-curve-chart-container">
      <ReactApexChart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default PowerCurveChart;
